import { createStore } from 'vuex'
import auth from './modules/auth.js'
import talent from './modules/Talents.js'
import skills from './modules/skills.js'

export default createStore({
  state: {
    skillsDone: 0
  },
  getters: {
    getSkillStatus(state){
      return state.skillsDone
    }
  },
  mutations: {
    changeSkill(state, value){
      state.skillsDone = value
    }
  },
  actions: {
  },
  modules: {
    auth,talent, skills
  }
})
