<template>
    <div :class="[screenWidth < 768 ? 'max-h-screen': 'min-h-screen']" class="fixed inset-0 w-full bg-black/70 flex justify-center items-center z-30">
       <div :class="[screenWidth < 768 ? 'h-auto': 'min-h-[40vh]']" class="flex md:w-3/4 w-11/12">
            <div 
            :class="[bgColor ? 'landing-screen': 'bg-white']"
            class="md:basis-3/5 basis-full w-full relative">
                <button @click="$emit('openModal', 4)" class="border-none md:hidden block absolute top-7 right-2 pr-3 pt-3"
                :class="[bgColor ? 'text-white': 'text-black']">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-9 h-9">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                <component :loading="loading" class="w-full" @changeComponent="changeComponent" :is="component"></component>
                <p class="text-red-600 text-lg my-2 text-center" v-if="errMessage">{{errMessage}}</p>
                <p class="text-center w-full text-base" :class="[bgColor ? 'text-white': 'text-black']">
                    Already have an account? 
                    <span @click="$emit('openModal', 1)" class="pb-3 inline-block font-bold cursor-pointer text-button-primary">Log in</span> 
                </p>
            </div>
            <div class="md:flex hidden basis-2/5 bg-[url('@/assets/images/login.png')] bg-cover flex-col relative  w-full">
                <button @click="$emit('openModal', 4)" class="border-none text-white self-end pr-3 pt-3">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                <p class="absolute bottom-2 left-2 self-start text-xs">Photo by Christina @ wocintechchat.com on Unsplash</p>
            </div>
       </div>
    </div>
</template>
<script>
import StepOne from './signup_components/StepOne.vue'
import StepTwo from './signup_components/StepTwo.vue'
import StepThree from './signup_components/StepThree.vue'

import ClientAPI from '@/services/Client.js'
export default {
    components:{
       
        StepOne,
        StepTwo,
        StepThree
    },
    data (){
        return {
            screenWidth: window.screen.availHeight,
            bgColor: true,
            component: null,
            loading: false,
            errMessage: '',
            clientData: {
                preference: {},
                projectType: 'None of the above',
                clientInfo: {}
            }
        }
    },
    methods:{
        changeComponent(value){
            if(value.from === 1){
                this.clientData.preference = value.data
                this.bgColor = true
                this.component = StepThree
                
            }else if(value.from === 2){
                this.clientData.projectType = value.data
                this.bgColor = true
                this.component = StepThree
            }else if(value.from === 3){
                this.loading = true
                this.clientData.clientInfo = value.data
                this.sendData()
                
            }
        },
        async sendData(){
            await ClientAPI.register(this.clientData)
                .then((response)=>{
                    console.log(response)
                    this.$store.commit('auth/SETUSER', response.data)
                    this.$store.commit('auth/SETTOKEN',response.access_token)
                    setTimeout(()=>{
                        this.$router.push('/explore')
                        window.location.reload()
                    },1000)
                })
                .catch((error)=>{
                    this.loading= false
                    if(error.code === "ERR_BAD_REQUEST"){
                        if('email'in error.response.data.errors){
                            this.errMessage = 'The email is already in use'
                        }else if('phone_number' in error.response.data.errors){
                            this.errMessage = error.response.data.errors.phone_number[0]
                        }else if('website_url' in error.response.data.errors){
                            this.errMessage = error.response.data.errors.website_url[0]
                        }else{
                            this.errMessage = 'Data incorrect'
                        }
                    }else if(error.code === "ERR_NETWORK"){
                        this.errMessage = 'Check your internet connection'
                    }else{
                        console.log('Something told me it was over baby')
                    }
                    console.log(error)
                })
        }
    },
    mounted () {
        this.component = StepOne
        console.log(this.screenWidth)
    }
}
</script>
<style>
.landing-screen{
  background-color: #171B1D;
}
</style>
