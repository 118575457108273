import axios from 'axios'
var convert = require('xml-js')

const baseURL = 'https://api.robitalent.com/api/v1'

class OpenAPI{
    //get skills
    static getSkillsFull(){
        return new Promise(async(resolve,reject)=>{
            try{
                const res = await axios({
                    method: 'GET',
                    url: baseURL+'/skills',
                })
                const data = res.data.data
                resolve(data)
            }catch(error){
                reject(error)
            }
        })
    }
    // get skills only name
    static getSkills(id){
        return new Promise(async(resolve,reject)=>{
            try{
                const res = await axios({
                    method: 'GET',
                    url: baseURL+'/skills',
                    params:{
                        skill_type_id: id
                    }
                })
                var skills = []
                const data = res.data.data
                for(var i=0;i<data.length; i++){
                    skills.push(data[i].name)
                }
                resolve(skills)
            }catch(error){
                reject(error)
            }
        })
    }

    // get talents to display 
    static getTalents(){
        return new Promise(async(resolve,reject)=>{
            try{
                const res = await axios({
                    method: 'GET',
                    url: baseURL+'/talents',
                    params:{
                        name: '',
                        seniority: 'Senior',
                        skills: 4,
                        skills_skill_type_id: 10002
                    }
                })
                const data = res.data.data
                resolve(data)
            }catch (error){
                reject(error)
            }
        })
    }
    // Talent email check before register process
    static talentEmail(email){
        return new Promise(async(resolve,reject)=>{
            try{
                const res = await axios({
                    method: 'POST',
                    url: baseURL+'/talent/check-email',
                    data:{
                        email: email
                    }
                })
                const data = res.data.data
                resolve(data)
            }catch(error){
                reject(error)
            }
        })
    }


    // Register talent
    static registerTalent(talentInfo){
        return new Promise(async(resolve,reject)=>{
            try {
                const res = await axios({
                    method: 'POST',
                    url: baseURL+'/talent/register',
                    headers:{
                        'Content-Type' : 'multipart/form-data',
                    },
                    data: talentInfo
                })
                const data = res.data.data
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }
    
    // get rss feed to display
    static getRssFeed(){
        return new Promise(async(resolve,reject)=>{
            try{
                const res = await axios({
                    method: 'GET',
                    url: baseURL+'/rss/tech-crunch'   // https://rss.app/feeds/7H8Mup0rNkxQvKPU.xml
                })
                const data = res.data
                var parsedData = convert.xml2json(data,{compact: true, spaces: 4})
                resolve(parsedData)
            }catch(error){
                console.log(error)
                reject(error)
            }
        })
    }

    // sends contact us information
    static sendMessages(messageParameters){
        return new Promise(async(resolve, reject)=>{
            try{
                const res = await axios({
                    method: 'POST',
                    url: baseURL+'/messages',
                    data: {
                        name: messageParameters.name,
                        email: messageParameters.email,
                        subject: messageParameters.subject,
                        description: messageParameters.description
                    }
                })
                const data = res.data.data
                resolve(data)
            }catch(error){
                reject(error)
            }
        })
    }
}

export default OpenAPI