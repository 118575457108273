import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from "vue-gtag";
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3'
import './assets/tailwind.css'
import Toaster from '@meforma/vue-toaster';

const VueGtagProperties ={
    config: { id: "G-82SXBMBQ31" },
}

const toastOption = {
    position: "top"
}

const tawkProperties = {
    propertyId: '63ce4336c2f1ac1e202f189b',
    widgetId: '1gnerd3ls'
}

createApp(App).use(store).use(router).use(VueGtag,VueGtagProperties,router).use(Toaster, toastOption).use(VueTelInput).use(TawkMessengerVue,tawkProperties).mount('#app')
