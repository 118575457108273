import axios from 'axios'


const baseURL = 'https://api.robitalent.com/api/v1/client'

class ClientAPI{
    // Login for the client
    static login(email,password){
        return new Promise(async(resolve, reject)=>{
            try{
                const res = await axios({
                    method: 'POST',
                    url: baseURL+'/login',
                    data:{
                        email: email,
                        password: password
                    }
                })
                const data = res.data
                resolve(data)
            }catch(error){
                reject(error)
            }
        })
    }
    // Register for new Client
    static register(userInfo){
        console.log(userInfo)
        return new Promise(async(resolve,reject)=>{
            try {
                const res = await axios({
                    method: 'POST',
                    url: baseURL+'/register',
                    data: {
                        first_name: userInfo.clientInfo.first_name,
                        last_name: userInfo.clientInfo.last_name,
                        job_title: userInfo.clientInfo.job_title,
                        email: userInfo.clientInfo.email,
                        company_name: userInfo.clientInfo.company_name,
                        phone_number: userInfo.clientInfo.phone_number,
                        password: userInfo.clientInfo.password,
                        password_confirmation: userInfo.clientInfo.confirm_password,
                        website_url: 'https://'+userInfo.clientInfo.website,
                        talent_quantity: userInfo.preference.amount,
                        talent_type: userInfo.preference.freelancerType ,
                        skill_ids: [] ,
                        project_type: userInfo.projectType ,
                    }
                })
                const data = res.data
                resolve(data)
            } catch (error) {
                reject(error)
            }
        })
    }

    // sends password reset link by given email
    static forgotPassword(email){
       return new Promise(async(resolve,reject)=>{
           try{
               const res = await axios({
                   method: 'POST',
                   url: baseURL+'/forgot-password',
                   data:{
                       email:email
                   }
               })
               const data = res.data
               resolve(data)
           }catch(error){
               reject(error)
           }
       })
   }

   // reset new password
   static resetPassword(newCredentials){
    return new Promise(async(resolve,reject)=>{
        try{
            const res = await axios({
                method: 'POST',
                url: baseURL+'/reset-password',
                data: {
                    email: newCredentials.email,
                    password: newCredentials.password,
                    password_confirmation: newCredentials.password_confirmation,
                    token: newCredentials.token
                }
            })
            const data = res.data
            resolve(data)
        }catch(error){
            reject(error)
        }
    })
   }

   // logs out client
   static logout(){
       return new Promise(async(resolve,reject)=>{
           try{
               const res = await axios({
                   method: 'POST',
                   url: baseURL+'/logout'
               })
               const data = res.data.
               resolve(data)
           }catch(error){
               reject(error)
           }
       })
   }
}

export default ClientAPI