<template>
    <div class="md:px-10 px-2">
        <div class="mt-9 flex flex-col justify-center items-center w-full">
            <img class="h-9 " src="@/assets/logo_black.png"/>
        </div>
        <div class="flex justify-center items-center">
            <div class="flex flex-col">
                <p class="text-white/70 mt-6 mb-8 text-center">Start your journey with us, create your free account!</p>
                <form @submit.prevent="sendData" class="grid grid-cols-2 md:gap-x-6 gap-x-3 gap-y-4 w-full">
                    <input 
                    :class="{'border border-red-500': err[0]}"
                    class="pl-3 py-3 text-sm rounded-sm" 
                    required
                    type="text" 
                    placeholder="Company name *" 
                    v-model="userInfo.company_name"
                    />
                    <input 
                    :class="{'border border-red-500': err[1]}"
                    class="pl-3 py-3 text-sm rounded-sm" 
                    type="text"
                    required 
                    placeholder="Website *"
                    v-model="userInfo.website"
                    />
                    <input 
                    class="pl-3 py-3 text-sm rounded-sm" 
                    type="text" 
                    placeholder="Country"
                    v-model="userInfo.country"
                    />
                    <input
                    class="pl-3 py-3 text-sm rounded-sm" 
                    type="text" 
                    placeholder="City" 
                    v-model="userInfo.city"
                    />
                    <div class="flex md:flex-row flex-col w-full">
                        <input 
                        :class="{'border border-red-500': err[2]}"
                        class="md:w-1/2 w-full grow-0 pl-3 py-3 text-sm rounded-sm" 
                        type="text"
                        required 
                        placeholder="First Name *"
                        v-model="userInfo.first_name"
                        />
                        <input 
                        :class="{'border border-red-500': err[6]}"
                        class="md:w-1/2 w-full grow-0 md:ml-1 ml-0 md:mt-0 mt-1 pl-3 py-3 text-sm rounded-sm" 
                        type="text"
                        required 
                        placeholder="Last Name *"
                        v-model="userInfo.last_name"
                        />
                    </div>
                    
                    <input 
                    class="pl-3 py-3 place-self-start w-full text-sm rounded-sm" 
                    type="text" 
                    placeholder="Job Title"
                    v-model="userInfo.job_title"
                    />
                    <input 
                    :class="{'border border-red-500': err[3]}"
                    class="pl-3 py-3 text-sm rounded-sm" 
                    type="email" 
                    placeholder="Email *"
                    v-model="userInfo.email"
                    />
                    <span class="flex w-full">
                        <!-- <p class="md:w-1/3 w-1/3 bg-white py-3 md:px-1 px-0 md:text-xs text-[9px] rounded-sm"><img class="inline-block md:w-auto w-4" src="@/assets/images/flag.png"/> +251</p> -->
                        <!-- <input 
                        :class="{'border border-red-500': err[4]}"
                        class="md:ml-2 ml-1 pl-3 py-3 text-sm rounded-sm w-full" 
                        type="number" 
                        v-model="userInfo.phone_number"
                        /> -->
                        <vue-tel-input ref="phone_number"></vue-tel-input>
                    </span>
                    <input 
                    :class="{'border border-red-500': err[5]}"
                    class="pl-3 py-3 text-sm rounded-sm" 
                    type="password" 
                    required
                    placeholder="Password *"
                    v-model="userInfo.password"
                    />
                    <input 
                    :class="{'border border-red-500': err[5]}"
                    class="pl-3 py-3 text-sm rounded-sm" 
                    type="password"
                    required 
                    placeholder="Confirm password *"
                    v-model="userInfo.confirm_password"
                    />
                    <span class="flex items-center text-white">
                        <input type="checkbox" id="terms"  v-model="userInfo.agreeTerms"/>
                        <label class="text-[13px] ml-2" for="terms">I agree to ROBiT's<span class="inline-block underline"> Terms of Service</span></label>
                    </span>
                     <span class="flex  items-center text-white">
                        <input type="checkbox" id="privacy" v-model="userInfo.agreePrivacy" />
                        <label class="text-[13px] ml-2" for="privacy">I agree to ROBiT's <span class="inline-block underline">Privacy Policy</span>.</label>
                    </span>
                </form>
                        
            </div>
        </div>
        <div class="flex flex-col justify-center items-center">
            <p v-if="errMessage" class="text-red-500">{{errMessage}}</p>
            <button
            v-if="!loading"   
            @click="sendData" 
            class="mt-6 mb-5 px-12 py-2 text-white bg-button-primary rounded-md text-xl font-medium">
                Sign up
            </button>
            <LoadingButton class="mt-8" v-else />
        </div>
    </div>
        
</template>
<script>
import {ref} from 'vue'
import LoadingButton from '@/components/LoadingButton.vue'
export default {
    props:['loading'],
    components: {
        LoadingButton
    },
    setup () {
        const phone_number = ref(null)
        return {
            phone_number
        }
    },
    data () {
        return {
            userInfo:{
                company_name: '',
                website: '',
                country: '',
                city: '',
                first_name: '',
                last_name: '',
                job_title: '',
                email: '',
                phone_number: '',
                password: '',
                confirm_password: '',
                agreeTerms: false,
                agreePrivacy: false
            },
            errMessage: '',
            err: ['','','','','','','','',''],
        }
    },
    methods: {
        onSelect({name, iso2, dialCode}) {
           console.log(name, iso2, dialCode);
        },
        sendData () {
            if(this.isValid()){
                this.$emit('changeComponent',{'from':3, 'data': this.userInfo })
            }
        },
        isValid(){
            this.errMessage = ''
            console.log(this.userInfo.phone_number)
            let phoneNumber = this.phone_number.phone
            if(this.userInfo.company_name.length < 1){
                this.err[0] = '1'
                this.errMessage ='Fill in your company name'
                return false
            }
            if(this.userInfo.website.length < 1){
                this.err[1] = '1'
                this.errMessage ='Fill in your company\'s website'
                return false
            }
            if(this.userInfo.first_name.length < 1){
                this.err[2] = '1'
                this.errMessage = 'Fill in your first name'
                return false
            }
            if(this.userInfo.last_name.length < 1){
                this.err[6] = '1'
                this.errMessage ='Fill in your last name'
                return false
            }
            if(this.userInfo.email.length < 1){
                this.err[3] = '1'
                this.errMessage = 'Fill in your email address'
                return false
            }
            if(phoneNumber.length < 0){
                this.err[4] = '1'
                this.errMessage ='Fill in your phone number please'
                return false
            }
            // if((phoneNumber.charAt[0] != '9' || phoneNumber.charAt[0] != '7') || phoneNumber.length != 9 ){
            //     this.err[4] = 'Fill in correct phone number'
            //     return false
            // }
            if(this.userInfo.password.length < 8){
                this.userInfo.password = ''
                this.userInfo.confirm_password = ''
                this.err[5] = '1'
                this.errMessage = 'Password length must be greater than 8 characters'
                return false
            }
            if(this.userInfo.password.length < 1){
                this.userInfo.password = ''
                this.userInfo.confirm_password = ''
                this.err[5] = '1'
                this.errMessage ='Fill in your password'
                return false
            }
            if(this.userInfo.password !== this.userInfo.confirm_password){
                this.userInfo.password = ''
                this.userInfo.confirm_password = ''
                this.err[5] = '1'
                this.errMessage ='Your password confirmation doesnt match'
                return false
            }
            if(this.userInfo.confirm_password.length <1){
                this.userInfo.password = ''
                this.userInfo.confirm_password = ''
                this.err[5] = '1'
                this.errMessage = 'fill password confirmation'
                return false
            }if(!this.userInfo.agreeTerms || !this.userInfo.agreePrivacy){
                this.err[7] = '1'
                this.errMessage= 'Please agree to the terms'
                return false
            }
            return true
        }
    }
}
</script>
<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>