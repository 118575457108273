<template>
    <div  class="fixed inset-0 min-h-screen w-full bg-black/70 flex justify-center items-center z-30">
        <div class="flex flex-col min-h-[50vh] md:w-1/2 w-11/12 p-9 landing-screen">
            <span class="flex justify-between w-full">
                <img class="h-9" src="@/assets/logo_black.png" />
                <svg @click="$emit('openModal', 4)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 cursor-pointer text-white">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </span>
            
            <div class="mt-9 flex flex-col justify-center items-center space-y-7 md:px-20 px-1">
                <p class="md:text-xl text-sm text-white">Forgot your password?</p>
                <input type="text" class="py-3 pl-3 md:text-sm text-xs w-full" placeholder="Enter your Email" v-model="newEmail"/>
                <p v-if="errMessage" class="text-red-500 text-center">{{ errMessage }}</p>
                <button v-if="!loading" @click="sendEmail" class="w-full text-white py-2 bg-button-primary rounded-md">Submit</button>
                <LoadingButton v-else />
                <p class="text-base text-white">Back to <span @click="$emit('openModal', 1)" class="font-bold text-button-primary hover:underline cursor-pointer">Log in</span></p>
            </div>
        </div>
    </div>
</template>
<script>
// reset api
import ClientAPI from '@/services/Client.js'
// loading button
import LoadingButton from '@/components/LoadingButton.vue'
export default {
    components:{
        LoadingButton
    },
    data () {
        return {
            loading: false,
            newEmail: '',
            errMessage: null
        }
    },
    methods:{
        async sendEmail () {
            this.loading=true
            this.errMessage = null
            if(this.newEmail){
                 await ClientAPI.forgotPassword(this.newEmail)
                .then((response)=>{
                    console.log(response)
                    this.$store.commit('auth/SETEMAIL',this.newEmail)
                    setTimeout(()=>{
                        this.$router.push('/reset')
                    },1000)
                })
                .catch((error)=>{
                    this.loading = false
                    if('data' in error.response){
                        console.log('hello hello')
                        if(error.response.data.errors.email){
                            this.errMessage = 'Invalid Email'
                        }
                    }
                    console.log(error)
                })
            }else{
                this.loading = false
                this.errMessage = 'Fill in your email'
            }
           
            // this.$emit('openModal', 4)
        }
    }
}
</script>
<style>
.landing-screen{
  background-color: #171B1D;
}
</style>
