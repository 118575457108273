<template>
    <transition class="slide-in">
        <div 
        v-show="slideCheck(index)"
        class="flex flex-col md:w-[450px] w-[200px] bg-white shadow-md rounded-md ">
            <img class="pt-2 self-center w-[263px] md:h-[260px] h-[200px]" :src="talent.profile_image_path" />
            <div :class="[screenHeight < 1366 ? 'md:px-2':'md:px-10']" class="py-5 px-2 flex flex-col items-center">
                <p class="md:text-3xl text-xs font-medium">{{ talent.first_name }} {{ talent.last_name[0].toUpperCase() }}</p>
                <p class="md:text-xl text-[10px] text-[#9D9D9D]">{{ talent.title }}</p>
                <span :class="[screenHeight < 1366 ? 'md:px-8':'md:px-4']" class="px-4 mt-3 grid grid-cols-2 gap-2 w-full">
                    <p 
                    v-for="skill in talent.skills.slice(0,4)" 
                    :key="skill" 
                    class="basis-2/5 rounded-lg bg-[#F5F5F5] py-1 text-center md:text-sm text-[10px] font-normal">
                        {{skill.name}}
                    </p>
                </span>
                <button @click="$emit('talentClicked',{'name': talent.first_name, 'id': talent.id})" class="mt-3 border-2 border-button-primary bg-transparent rounded-2xl md:text-sm text-[10px] py-1 px-4">See more</button>
            </div>
        </div>
    </transition>        
</template>
<script>
export default {
    props: ["talent","currentSlide","index"],
    data () {
        return {
            screenHeight: window.screen.availWidth
        }
    },
    methods: {
        slideCheck(index){
            if(this.currentSlide.includes(index)){
                return true
            }else{
                return false
            }
        }
    }
}
</script>
<style>
.carousel-item{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.slide-in-enter-active,
.slide-in-leave-active {
    /* transition: all 1s ease-in-out; */
    -webkit-transition: all 1s ease-out;
    transition: all 1s ease-out;
}
.slide-in-enter-from{
    transform: translateX(-100%)
}
.slide-in-leave-to{
    transform: translateX(100%)
}
</style>