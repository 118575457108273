<template>
    <div class="md:py-20 py-10 md:px-36 px-20">
        <div class="flex justify-center">
            <img class="h-9 md:justify-center" src="@/assets/logo_black.png"/>
        </div>
        <p class="md:mb-5 mb-4 md:mt-28 mt-5 md:text-lg text-xs text-center">What type of project are you hiring for?</p>
        <div class="flex justify-center items-center">
            <div class="ml-3 flex flex-col space-y-2">            
                <span class=" flex items-center">
                    <input type="radio" id="one" value="New idea or project" v-model="projectType"/>
                    <label class="ml-2 md:text-base text-[10px]" for="one">New idea or project</label> 
                </span>
                <span class=" flex items-center">
                    <input type="radio" id="two" value="Existing project that needs more resources" v-model="projectType"/>
                    <label class="ml-2 md:text-base text-[10px]" for="two">Existing project that needs more resources</label> 
                </span>
                <span class=" flex items-center">
                    <input type="radio" id="three" value="Ongoing assistance or consultation" v-model="projectType"/>
                    <label class="ml-2 md:text-base text-[10px]" for="three">Ongoing assistance or consultation</label> 
                </span>
                <span class=" flex items-center">
                    <input type="radio" id="four" value="None of the above" v-model="projectType"/>
                    <label class="ml-2 md:text-base text-[10px]" for="four">None of the above</label> 
                </span>
            </div>  
        </div>
        <span class="mt-16 flex flex-col justify-center items-center">
            <p class="w-full text-red-500 text-center">{{err}}</p>
            <button 
                @click="sendData"
                class="mt-3 px-12 py-2 bg-transparent border border-button-primary rounded-md hover:bg-button-primary text-xl font-medium">
                Next
            </button>     
        </span>
    </div>
</template>
<script>
export default {
    data () {
        return {
            projectType: '',
            err: ''
        }
    },
    methods: {
        sendData(){
            if(this.projectType.length > 1){
                this.$emit('changeComponent',{'from': 2,'data': this.projectType})
            }else{
                this.err = 'Select what type of project you are hiring for'
                console.log('problem with the logic maybe')
            }
        }
    }
}
</script>