import { createRouter, createWebHistory } from 'vue-router'
import MainView from '../views/MainVue.vue'
import HomeView from '../views/HomeView.vue'


const routes = [
  {
    path: '/',
    name: 'Main',
    component: MainView,
    children:[
      {
        path: '',
        name: 'Home',
        component: HomeView
      },
      {
        path: 'explore',
        name: 'Explore',
        component: () => import('@/views/ExploreTalentView.vue')
      },
      {
        path: 'about',
        name: 'About',
        component: () => import('@/views/AboutUs.vue')
      },
      {
        path: 'resources',
        name: 'Resources',
        component: () => import('@/views/ResourcesView.vue')
      },
      {
        path:'privacypolicy',
        name: 'Privacy',
        component: () => import('@/views/PrivacyPolicyView.vue')
      },
      {
        path: 'profile/:name',
        name: 'Profile',
        component: () => import('@/views/TalentProfile.vue'),
        beforeEnter: (to,from,next) => {
          var isRedirect = null
          if(from.name){
            isRedirect = from.name.toString().match(/(Explore|Profile|Project|Home|Main)/g)
          }
          if(isRedirect){
            if(localStorage.getItem("user")){
              next()
            }else{
              next({path: '/'})
            }
          }else{
            next({path: '/'})
          }
        }
      },
      {
        path: 'project/:name',
        name: 'Project',
        component: () => import('@/views/ProjectCatalog.vue'),
        beforeEnter: (to,from,next) => {
          var isRedirect = null
          if(from.name){
            isRedirect = from.name.toString().match(/(Project|Profile)/g)
          }
          if(isRedirect){
            if(localStorage.getItem("user")){
              next()
            }else{
              next({path: '/'})
            }
          }else{
            next({path: '/'})
          }
        }
      }
    ]
  },
  {
    path: '/reset-password',
    name: 'Reset_password',
    component: () => import('@/views/ResetPassword.vue')
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import('@/components/reset-password/CheckEmail.vue')
  },
  {
    path: '/reset-successfull',
    name:'reset_successfull',
    component: () => import('@/components/reset-password/ResetComplete.vue')
  },
  {
    path: '/talent',
    name:'RegisterTalent',
    component: () => import('@/views/talent_registration/TalentEmail.vue')
  },
  {
    path: '/talent-detail',
    name: 'RegisterTalentDetail',
    component: () => import('@/views/talent_registration/TalentDetail.vue'),
    beforeEnter: (to,from,next)=>{
      console.log(from.name)
      var isRedirect = null
      if(from.name){
        isRedirect = from.name.toString().match(/(RegisterTalent)/g)
      }
      console.log(isRedirect)
      if(isRedirect){
        next()
      }else{
        next('/')
      }
    },
    children: [
      {
        path: '',
        name: 'Talent_Profile',
        component:() => import('@/views/talent_registration/TalentProfile.vue'),
        // beforeEnter: (to,from,next)=>{
        //   console.log(from.name)
        //   var isRedirect = null
        //   if(from.name){
        //     isRedirect = from.name.toString().match(/(RegisterTalentDetail | RegisterTalent | Talent_History)/g)
        //   }
        //   if(isRedirect){
        //     next()
        //   }else{
        //     next('/')
        //   }
        // }
      },
      {
        path: 'history',
        name: 'Talent_History',
        component: () => import('@/views/talent_registration/TalentHistory.vue'),
        beforeEnter: (to,from,next)=>{
          var isRedirect = null
          if(from.name){
            isRedirect = from.name.toString().match(/(Talent_Profile| Talent_Skill)/g)
          }
          if(isRedirect){
            next()
          }else{
            next('/')
          }
        }
      },
      {
        path: 'skill',
        name: 'Talent_Skill',
        component: () => import('@/views/talent_registration/TalentSkill.vue'),
        beforeEnter: (to,from,next)=>{
          var isRedirect = null
          if(from.name){
            isRedirect = from.name.toString().match(/(Talent_History)/g)
          }
          if(isRedirect){
            next()
          }else{
            next('/')
          }
        }
      },
      
    ]
  },
  {
    path: '/registration-complete',
    name: 'registration-complete',
    component: () => import('@/views/talent_registration/RegistrationComplete.vue')
  },
  {
    path:'/:pathMatch(.*)*',
    component: () => import('@/components/notFound.vue')
  }
  
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
