
const state = {
    user: {},
    returnRoute: '',
    email: '',
    loginFlag: 0
}

const getters = {
    getUser (state){
        if(localStorage.getItem('user')){
            state.user = JSON.parse(localStorage.getItem('user'))
        }else{
            state.user = null
        }
        return state.user
    },
    getToken(){
        localStorage.getItem('token')
    },
    getEmail(state){
        return state.email
    },
    setLoginFlag(state){
        return state.loginFlag
    }
}

const mutations = {
    SETTOKEN: (state,value)=>{
        console.log(value)
        localStorage.setItem('token',value)
    },
    SETUSER: (state, response)=>{
        localStorage.setItem('user', JSON.stringify(response,null,1))
    },
    LOGOUT:()=>{
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        localStorage.removeItem('talentID')
    },
    SETEMAIL: (state, email)=>{
        state.email = email
    },
    toogleLoginFlag: (state,value)=>{
        state.loginFlag = value
    }
}

const actions = {}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
}