<template>
  <div class="font-Poppins md:bg-slate-50 bg-white">
    <div :class="[screenHeight < 1366 ? 'md:px-16':'md:px-32']" class=" px-2 md:h-screen h-[50vh] w-full landing-screen flex md:flex-row flex-col justify-center items-center ">
      <div class="md:w-1/2 w-full flex flex-col">
        <p :class="[screenWidth < 768 ? 'md:text-5xl': 'md:text-tp']" class=" text-xl text-white font-extrabold">Let's help you hire the right talent</p>
        <p :class="[screenWidth < 768 ? 'md:text-lg': 'md:text-2xl']" class="mt-5 text-white font-normal  text-sm text-white/60">Looking to hire the finest Tech Talent for a competitive rate?  You've come to the right place! 
ROBiT is the marketplace where you can source upskilled, pre-vetted, pre-screened technologists from Africa, the Next Global Talent Powerhouse!</p>
        <span class="mt-12 ">
          <button @click="openTalents()" class="md:[188px] w-2/5 bg-button-primary hover:bg-[#4FE121] text-white font-semibold rounded-lg md:text-xl text-base md:px-7 px-5 md:py-3 py-2">Find Talent</button>
          <button @click="$router.push({name:'RegisterTalent'})" class="ml-4 md:[188px] w-2/5 bg-transparent hover:bg-button-primary border border-button-primary  text-button-primary hover:text-white font-semibold rounded-lg md:text-xl text-base md:px-7 px-5 md:py-3 py-2">Talent Sign up</button>
        </span>
        
      </div>
      <img class="md:block hidden w-1/2" src="@/assets/images/rafiki.png"/>
      <!-- curve svg -->
      <svg class="md:block absolute hidden bottom-0 left-0 z-0" xmlns:xlink="http://www.w3.org/1999/xlink" id="wave" style="transform:rotate(0deg); transition: 0.3s" viewBox="0 0 1440 130" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0"><stop stop-color="rgba(255, 255, 255, 1)" offset="0%"/><stop stop-color="rgba(237.616, 237.616, 237.616, 1)" offset="100%"/></linearGradient></defs><path style="transform:translate(0, 0px); opacity:1" fill="url(#sw-gradient-0)" d="M0,26L34.3,41.2C68.6,56,137,87,206,101.8C274.3,117,343,117,411,106.2C480,95,549,74,617,73.7C685.7,74,754,95,823,93.2C891.4,91,960,65,1029,52C1097.1,39,1166,39,1234,52C1302.9,65,1371,91,1440,104C1508.6,117,1577,117,1646,97.5C1714.3,78,1783,39,1851,30.3C1920,22,1989,43,2057,58.5C2125.7,74,2194,82,2263,78C2331.4,74,2400,56,2469,56.3C2537.1,56,2606,74,2674,86.7C2742.9,100,2811,108,2880,93.2C2948.6,78,3017,39,3086,34.7C3154.3,30,3223,61,3291,73.7C3360,87,3429,82,3497,84.5C3565.7,87,3634,95,3703,97.5C3771.4,100,3840,95,3909,93.2C3977.1,91,4046,91,4114,91C4182.9,91,4251,91,4320,88.8C4388.6,87,4457,82,4526,73.7C4594.3,65,4663,52,4731,47.7C4800,43,4869,48,4903,49.8L4937.1,52L4937.1,130L4902.9,130C4868.6,130,4800,130,4731,130C4662.9,130,4594,130,4526,130C4457.1,130,4389,130,4320,130C4251.4,130,4183,130,4114,130C4045.7,130,3977,130,3909,130C3840,130,3771,130,3703,130C3634.3,130,3566,130,3497,130C3428.6,130,3360,130,3291,130C3222.9,130,3154,130,3086,130C3017.1,130,2949,130,2880,130C2811.4,130,2743,130,2674,130C2605.7,130,2537,130,2469,130C2400,130,2331,130,2263,130C2194.3,130,2126,130,2057,130C1988.6,130,1920,130,1851,130C1782.9,130,1714,130,1646,130C1577.1,130,1509,130,1440,130C1371.4,130,1303,130,1234,130C1165.7,130,1097,130,1029,130C960,130,891,130,823,130C754.3,130,686,130,617,130C548.6,130,480,130,411,130C342.9,130,274,130,206,130C137.1,130,69,130,34,130L0,130Z"/></svg>
      <!-- <svg class="md:block absolute hidden bottom-0 left-0 z-0 xl:h-11/12" width="1512" height="192" viewBox="0 0 1512 192" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0C0 0 93.472 64.254 195.5 97.5C310.575 134.998 437.625 145.174 487.5 139.5C654.5 120.5 695.73 98.8442 951.5 79C1067.5 70 1232 94.5 1328.5 109C1435.5 125.078 1512 134 1512 134V192H0V0Z" fill="white"/>
      </svg> -->
      <!-- <svg class="md:block absolute hidden bottom-0 left-0 z-0 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="currentColor" fill-opacity="1" d="M0,128L120,149.3C240,171,480,213,720,240C960,267,1200,277,1320,282.7L1440,288L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg> -->
    </div>
    <div :class="[screenHeight < 1366 ? 'md:px-16':'md:px-32']" class="md:mt-0 mt-6 px-5 md:py-8 py-2 w-full md:bg-advert-green bg-transparent flex md:flex-row flex-col md:justify-between justify-start md:space-y-0 space-y-3 z-20">
      <span class="md:p-0 p-2 md:w-1/5 w-full md:bg-transparent bg-advert-green flex justify-start items-start space-x-3">
        <img class="h-16" src="@/assets/images/quality.png" alt="">
        <span class="flex flex-col md:space-y-2 space-y-1">
          <p class="md:text-xl text-lg font-semibold">Quality</p>
          <p class="md:text-sm text-xs text-black text-opacity-80">Our thorough Talent vetting & curation process is our quality assurance.</p>
        </span>
      </span>
      <span class="md:p-0 p-2 md:w-1/5 w-full md:bg-transparent bg-advert-green flex justify-start items-start space-x-3">
        <img src="@/assets/images/competitive.png" alt="">
        <span class="flex flex-col md:space-y-2 space-y-1">
          <p class="md:text-xl text-lg font-semibold">Competitive</p>
          <p class="md:text-sm text-xs text-black text-opacity-80">If you are looking to get value for money, you are in the right place!</p>
        </span>
      </span>
      <span class="md:p-0 p-2 md:w-1/4 w-full md:bg-transparent bg-advert-green flex justify-start md:items-start items-center space-x-3 ">
        <img class="w-20" src="@/assets/images/fast.png" alt="">
        <span class="flex flex-col md:space-y-2 space-y-1">
          <p class="md:text-xl text-lg font-semibold">Fast</p>
          <p class="md:text-sm text-xs text-black text-opacity-80">When hiring, time is of the essence & our mission is to significantly reduce time-to-fill through speedy matchmaking & smooth onboarding.</p>
        </span>
      </span>
      <span class="md:p-0 p-2 md:w-1/5 w-full md:bg-transparent bg-advert-green flex justify-start md:items-start items-center space-x-3 ">
        <img class="w-20" src="@/assets/images/secure.png" alt="">
        <span class="flex flex-col md:space-y-2 space-y-1">
          <p class="md:text-xl text-lg font-semibold">Secure</p>
          <p class="md:text-sm text-xs text-black text-opacity-80">Our certified, embedded payment gateway allows fast, reliable & secure payments.</p>
        </span>
      </span>
    </div>
    <div :class="[screenHeight < 1366 ? 'md:px-16':'md:px-32']" class="md:my-[84px] my-3 px-5 flex md:flex-row flex-col justify-between items-start">
      <div class="basis-1/2">
        <p class="md:text-3xl text-lg md:font-normal font-normal">Get a taste of great technologist all the way from Africa, an untapped Tech Talent Powerhouse!</p>
        <p class="md:text-base text-mp md:mt-6 mt-2">ROBiT is a quality-driven tech talent marketplace trying to showcase African Tech Talent to the world and bridge the gap between an untapped pool of professional developers in the continent with global opportunities. 
If you can't find the engineer profile you need in our pool, it's ok, you tell us your business needs and we will get you our hand-picked selection! Our Talents are looking to build a career, join a team and get started with you! If you are ready, they are!
Get started with us today and build your team right here!</p>
        <button @click="openTalents()" class="mt-12 md:block hidden bg-button-primary text-white font-semibold text-xl px-7 py-3 rounded">Find Talent</button>
      </div>
      <img class="basis-1/3 rounded" src="@/assets/images/home_network.png"/>
    </div>
    <div :class="[screenHeight < 1366 ? 'md:px-16':'md:px-32']" class="md:flex hidden home-advert py-12 justify-between items-center">
      <div class="basis-1/2">
        <p class="text-2xl font-semibold">Our Partners</p>
        <p class="mt-5 text-xl">Our success is our partnerships with the best in the market! The quality of our marketplace depends on the quality of our Talents. We source them from the finest, upskill them by the best and give you a satisfying experience! 
We are constantly growing our network of partners to achieve a seamless end-to-end hire experience!</p>
      </div>
      <div class="basis-2/5 grid grid-cols-3 grid-rows-2 grid-flow-col gap-x-4">
        <img class="w-44" src="@/assets/images/AASTU_Logo.png"/> 
        <img class="w-44" src="@/assets/images/AAU_Logo.png"/>
        <img class= "place-self-center w-36" src="@/assets/images/10_academy.png"/>
        <img class="pt-4 w-36" src="@/assets/images/BITS_Logo.png"/>
        <img class="place-self-center w-[400px]" src="@/assets/images/zowi.png"/>
        <img class="mt-2 ml-7 w-20" src="@/assets/images/santim_pay.png"/>
      </div>
    </div>
    <div class="flex flex-col justify-center md:px-32 px-2 md:py-10 py-0">
      <p class="text-center md:text-5xl text-lg font-medium">Explore from variety skillsets</p>
      <img class="md:mt-0 mt-4 w-full" src="@/assets/images/explore.png"/>
      <!-- <div class="md:flex hidden justify-between md:py-20 py-5">
        <div class="basis-1/4 flex flex-col justiy-center items-center">
          <img class="h-16" src="@/assets/icons/Non_vetted_icon.png"/>
          <p class="md:text-xl text-sm font-semibold md:mt-8 mt-4">Non-vetted</p>
          <p class="md:mt-3 mt-1 md:text-center text-left font-medium md:text-base text-[10px]">Non-vetted Talents are professionals unwilling to go through our screening and vetting process. You will have to do all the work!</p>
        </div>
        <div class="basis-1/4 flex flex-col justiy-center items-center">
          <img class="h-16" src="@/assets/icons/Screened_icon.png"/>
          <p class="md:text-xl text-sm font-semibold md:mt-8 mt-4">Screened</p>
          <p class="md:mt-3 mt-1 md:text-center text-left font-medium md:text-base text-[10px]">Talents screened through our pre-registration cloud-based coding assessments (coding challenges, automated grading and reporting, cheating detection...) Reports can be provided upon request</p>
        </div>
        <div class="basis-1/4 flex flex-col justiy-center items-center">
          <img class="h-16" src="@/assets/icons/Vetted_icon.png"/>
          <p class="md:text-xl text-sm font-semibold md:mt-8 mt-4">Vetted</p>
          <p class="md:mt-3 mt-1 md:text-center text-left font-medium md:text-base text-[10px]">Less than 1% make it through our 12 week rigorous upskilling process. A lot of hard work, learning and unlearning to finally get the vetted label!</p>
        </div>        
      </div> -->
    </div>
    <div :class="[screenHeight < 1366 ? 'md:px-16':'md:px-32']" class="px-2 md:py-10 py-4">
      <p class="text-center md:text-5xl text-xl font-medium">Meet our Top Talents...</p>
      <div class="w-full xl:mt-16 lg:mt-11 mt-6">
       <Carousel @openModal="$emit('openModal',1)" :talents="topTalent" /> 
        <!-- <Carousel class="" :slideWidth="25" :items-to-show="itemsToshow" :wrapAround="true" :transition="800">
          <Slide :class="[screenWidth < 768 ?  'h-[70vh]' : 'h-[50vh]']"  v-for="talent in topTalent" :key="talent.id"  :slidesCount="4" class="flex flex-col w-1/4 bg-white/10 shadow-md rounded-md mx-4 ">
            <img class="h-1/2" :src="talent.profile_image_path"/>
            <div class="py-5 md:px-20 px-1 flex flex-col items-center">
              <p class="md:text-3xl text-xs font-medium">{{talent.first_name}} {{talent.last_name}}</p>
              <p class="md:text-xl text-[10px] text-black text-opacity-70">{{talent.title}}</p>
              <span 
              class="mt-3 grid grid-cols-2 gap-2 w-full ">
                <p v-for="skill in talent.skills.slice(0,4)" :key="skill" class="basis-2/5 rounded-lg bg-gray-200 py-1 text-center md:text-sm text-[10px] font-normal">{{skill.name}}</p>
              </span>
              <button class="mt-3 border-2 border-button-primary bg-transparent rounded-2xl md:text-sm text-[10px] py-1 px-4">See more</button>
            </div>
          </Slide>
          <template #addons>
            <navigation/>
            <pagination/>
          </template>
        </Carousel> -->
      </div>
    </div>
    <div :class="[screenHeight < 1366 ? 'md:px-16':'md:px-32']" class="px-2 flex flex-col md:mt-16 mt-0 md:py-10 py-6 bg-white">
      <p class="text-center md:text-5xl text-xl font-medium">Hiring at your fingertips!</p>
      <div class="w-full flex md:flex-row flex-col justify-between md:py-[70px] py-5 md:space-y-0 space-y-4 items-start">
        <div class="basis-1/4 flex flex-col justify-center items-center">
          <img class="h-[159px]" src="@/assets/images/hiring_1.png"/>
          <p class="md:text-xl text-xs  font-semibold md:mt-8 mt-4">Browse</p>
          <p class="md:mt-3 mt-2 text-center font-normal md:text-base text-[10px]">The segmentation of our pool and profile curation allows you to choose the best fit for the position you are looking to fill. You can't find what you are looking for? Contact us directly & we will get you what you want in no time!</p>
        </div>
        <div class="basis-1/4 flex flex-col justify-center items-center">
          <img class="h-[159px]" src="@/assets/images/hiring_2.png"/>
          <p class="md:text-xl text-xs  font-semibold md:mt-8 mt-4">Meet our candidates</p>
          <p class="md:mt-3 mt-2 text-center font-normal md:text-base text-[10px]">You have found candidates you like, let's help you organize screening and interview sessions to meet them.</p>
        </div>
        <div class="basis-1/4 flex flex-col justify-center items-center">
          <img class="h-[159px]" src="@/assets/images/hiring_3.png"/>
          <p class="md:text-xl text-xs text-center font-semibold md:mt-8 mt-4">Pick your choice & get started!</p>
          <p class="md:mt-3 mt-2 text-center font-normal md:text-base text-[10px]">Once you have found the right fit, we will assist you in the contracting process, ensuring HR and payroll country compliance. You can now get started!</p>
        </div>
      </div>
      <button @click="$emit('openModal', 2)" class="md:mt-0 mt-6 self-center py-2 px-5 text-white bg-button-primary rounded md:text-2xl text-xs">Get Started</button>
    </div>
    <div :class="[screenHeight < 1366 ? 'md:px-16':'md:px-32']" class="w-full px-2 md:mt-16 mt-5">
      <span class="flex md:flex-row flex-col justify-center md:space-x-5 space-x-0 md:space-y-0 space-y-2">
        <p class="text-center md:text-3xl text-xl md:font-medium font-bold">What skills are you looking for?</p>
        <!-- fix the search bar here -->
        <span class="flex">
          <div class="md:self-end self-start flex md:w-auto w-full">
            <VueMultiselect
            v-model="skillName"
            :options="skills"
            :multiple="true"
            >

            </VueMultiselect>
            <!-- search icon -->
            <button @click="searchSkill" class="px-3 bg-button-primary rounded-r-md text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
              </svg>
            </button>
            
          </div>
          <!-- <span @click="searchSkill" class="ml-2 inline md:hidden bg-button-primary text-white cursor-pointer text-center px-1 pt-1 rounded-r-sm">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
            </svg>
          </span> -->
        </span>
        
        
      </span>
      <p class="mt-11 md:block hidden px-14 text-base font-medium text-black text-center text-opacity-80">Our network of developers includes talents across a broad range of expertise in software development. Find your next team member in our pool of African Tech Talent.</p>
      <div class="md:mt-9 mt-4 md:pb-32 pb-6 w-full flex justify-evenly flex-wrap">
        <button 
        v-for="(skill,index) in skills.slice(0,25)" :key="index"
        @click="addSkill(skill)"
        :class="{'bg-button-primary text-white': skillName.find((s)=>{ return s === skill})}"
        class="md:ml-5 ml-2 md:mt-5 mt-2 cursor-pointer border border-button-primary py-1 md:px-6 px-4 md:text-xl text-xs font-medium bg-transparent active:bg-button-primary active:text-white">
            {{skill}}
        </button>
      </div>
        
    </div>
  </div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.css'
import VueMultiselect from 'vue-multiselect'
// import 'vue3-carousel/dist/carousel.css'
// import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import Carousel from '@/components/carousel/Carousel.vue'
import OpenAPI from '@/services/OpenRoutes.js'
// @ is an alias to /src
export default {
  components: {
    Carousel,
    // Slide,
    // Pagination,
    // Navigation,
    VueMultiselect
  },
  data () {
    return {
      screenWidth: window.screen.availHeight,
      screenHeight: window.screen.availWidth,
      itemsToshow: 3,
      skillName: [],
      skills: [],
      topTalent:[]
    }
  },
  watch:{
    screenWidth(value){
      console.log(this.screenWidth)
      if(value < 768){
        this.itemsToshow = 2
        console.log(this.itemsToshow)
      }
    }
  },
  computed:{
    userPresent(){
      return this.$store.getters['auth/getUser']
    }
  },
  methods:{
    openTalents(){
      if(this.userPresent){
        this.$router.push('/explore')
      }else{
        this.$emit('openModal',1)
      }
    },
    addSkill(skill){
      if(!this.skillName.find((s)=>{ return s === skill})){
        this.skillName.push(skill)
      }else{
        this.skillName = this.skillName.filter(e => e != skill)
      }      
    },
    searchSkill () {
      if(this.skillName.length > 0){
        this.$store.dispatch('skills/setSkills', this.skillName)
        if(this.userPresent){
          this.$router.push('/explore')
        }else{
          this.$emit('openModal', 1)
        }
      }      
    }
  },
  async created (){
    this.$emit("updateHeader", true)
    this.$emit("updateMenu", 0)
    await OpenAPI.getTalents()
      .then((response)=>{
        this.topTalent = response
      })
    await OpenAPI.getSkills()
      .then((response)=>{
        this.skills = response
      })
  }
}
</script>
<style>
.home-advert{
  background-color: #D7E6D5;
}
.landing-screen{
  background-color: #171B1D;
}
</style>
