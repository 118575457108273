<template>
    <header-component-vue :key="headerKey" @openModal="openModal" :whiteBg="bgWhite" :menuClicked="menuClicked" />
    <router-view @updateHeader="updateHeader" @updateMenu="updateMenu" @openModal="openModal"></router-view>
    <LoginModal v-if="loginView" @openModal="openModal"  />
    <SignupModal v-if="signUpView" @openModal="openModal" />
    <ForgetModal v-if="forgetView" @openModal="openModal" />
    <footer-component-vue @openModal="openModal"/>
</template>
<script>
import '../assets/tailwind.css'
import LoginModal from '../components/Initial_forms/LoginView.vue'
import SignupModal from '@/components/Initial_forms/SignUpModal.vue'
import ForgetModal from '@/components/Initial_forms/ForgetPassword.vue'
import HeaderComponentVue from '../components/HeaderComponent.vue';
import FooterComponentVue from '../components/FooterComponent.vue'

export default  {
  components:{
    HeaderComponentVue,
    FooterComponentVue,
    LoginModal,
    SignupModal,
    ForgetModal
  },
  data () {
    return {
      bgWhite: false,
      menuClicked: 0, 
      headerKey: 1,
      loginView: false,
      signUpView: false,
      forgetView: false
    }
  }, 
  methods:{
    openModal(value){
      if(value === 1){
        this.forgetView = false
        this.signUpView = false
        this.loginView = true
      }else if(value === 2){
        this.forgetView = false
        this.loginView = false
        this.signUpView = true
      }else if (value === 3){
        this.loginView = false
        this.signUpView = false
        this.forgetView = true
      }else if(value === 4){
        this.forgetView = false
        this.loginView = false
        this.signUpView = false
        // logic to update the header 
      }else{
        console.log("still thinking about it")
      }
    },
    updateHeader (value){
      this.bgWhite = value
      this.headerKey += 1 
    },
    updateMenu (value){
      this.menuClicked = value
    }
  },
  mounted () {
    if(this.$store.getters['auth/setLoginFlag']){
      this.$store.commit('auth/toogleLoginFlag', 0)
      this.openModal(1)
    }
  }
}
</script>