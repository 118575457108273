<template>
    <div class="fixed inset-0 min-h-screen w-full bg-black/70 flex justify-center items-center z-30">
       <div class="flex min-h-[70vh] md:w-2/3 w-11/12">
          <div class="md:basis-3/5 basis-full py-10 px-12 landing-screen relative">
            <button @click="$emit('openModal', 4)" class="border-none md:hidden text-white block absolute top-7 right-2 pr-3 pt-3"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-9 h-9">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>  
            <img class="h-9" src="@/assets/logo_black.png"/>
            <div class="flex justify-center items-center">
                <form @submit.prevent="handleSubmit" class="flex flex-col md:mt-28 mt-10">
                    <p class="text-white text-xl">Welcome back, you have been missed!</p>
                    <input 
                    :class="{'border-red-500': err[0]}" 
                    class="mt-8 bg-white w-full py-3 pl-3 text-sm rounded" 
                    placeholder="Email" 
                    type="email" 
                    v-model="email"
                    />
                    <p v-if="err[0]" class="text-red-500 text-center">{{err[0]}}</p>
                    <input 
                    :class="{'border-red-500': err[1]}" 
                    class="md:my-4 my-3 bg-white w-full py-3 pl-3 text-sm rounded" 
                    placeholder="Password" 
                    type="password"
                    v-model="password"
                    />
                    <p v-if="err[1]" class="text-red-500 text-center">{{err[1]}}</p>
                    <p @click="$emit('openModal', 3)" class="text-white text-base self-end cursor-pointer hover:underline">Forget your password?</p>
                    <span class="flex items-center mt-4"><input class="bg-transparent border border-white rounded-sm" type="checkbox" /> <p class="ml-1 text-sm text-white">Remember me</p></span>
                    <p v-if="err[2]" class="text-red-500 text-center">{{err[2]}}</p>
                    <button type="submit" class="mt-4 text-white text-xl py-2 bg-button-primary font-medium rounded">Log in</button>
                    <p class="mt-6 text-base text-white text-center">Don't have an account? <span @click="$emit('openModal', 2)" class="text-button-primary hover:underline cursor-pointer">Sign up</span> </p>
                </form>
            </div>
          </div>
          <div class="md:flex hidden basis-2/5 bg-[url('@/assets/images/login.png')] bg-cover relative  md:flex-col w-full">
              <button @click="$emit('openModal', 4)" class="border-none text-white self-end pr-3 pt-3">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
              </button>
              <p class="absolute bottom-2 left-2 self-start text-xs">Photo by Christina @ wocintechchat.com on Unsplash</p>
          </div>
      </div>
    </div>
</template>
<script>
import ClientAPI from '@/services/Client.js'
export default {
    data () {
        return {
            email: '',
            password: '',
            err: ['', '','']
        }
    },
    methods:{
        async handleSubmit(){
            if(this.isValid()){
                await ClientAPI.login(this.email, this.password).
                    then((response)=>{
                        
                        this.$store.commit('auth/SETUSER', response.data)
                        this.$store.commit('auth/SETTOKEN',response.access_token)
                        setTimeout(()=>{
                            this.$emit('openModal', 4)
                            if(this.$store.state.auth.returnRoute.length > 0){
                                this.$router.push({
                                    path: this.$store.state.auth.returnRoute
                                })
                            }else{
                                this.$router.push('/')
                            }
                            window.location.reload()  
                        },1000)
                        
                    })
                    .catch((error)=>{
                        if(error.response.data.message){
                            this.err[2] = 'Incorrect email or password'
                        }
                    })
            }
        },
        isValid(){
            if(this.email.length <1){
                this.err[0] = 'Enter your Email please'
                return false
            }
            if(this.password.length < 8){
                this.err[1] = 'Enter your password please'
                return false
            }
            return true
        }
    }   
}
</script>
<style>
.landing-screen{
  background-color: #171B1D;
}
</style>
