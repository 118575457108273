const state = {
    skills: []
}

const getters ={
    getSkills (state){
        return state.skills
    }
}

const actions = {
    setSkills ({commit}, skills){
        commit('setSkills', skills)
    }
}

const mutations = {
    setSkills (state, skills){
        state.skills = skills
    }
}


export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
}