import OpenAPI from "@/services/OpenRoutes"
const state = {
    skills: []
}

const getters = {}

const actions = {
    async getSkills(id){
        await OpenAPI.getSkills(id)
            .then((response)=>{
                return response                
            }).catch((error)=>{
                return []
            })
    }
}

const mutations = {}


export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
}