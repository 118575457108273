<template>
    <div :class="[screenHeight < 1366 ? 'md:px-16':'md:px-32']" class="px-2 footer-background flex flex-col justify-center items-center">
        <div :class="[screenWidth < 768? 'md:gap-20': 'md:gap-40']" class="grid md:grid-cols-4 grid-cols-2  grid-flow-row gap-10 w-full md:mt-28 mt-7 md:mb-24 mb-7">
            <div class="md:col-span-1 col-span-2 flex flex-col md:justify-start justify-center md:items-start items-center space-y-3">
                <img class="md:w-auto w-44" src="@/assets/logo_black.png" alt="">
                <p class="text-lg md:block hidden text-white">Home of African Talents!</p>
                <span class="flex space-x-3">
                    <!-- linkedin svg  -->
                    <svg class="text-white hover:text-button-primary cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77Z"/>
                    </svg>
                    <!-- twitter svg -->
                    <svg class="text-white hover:text-button-primary cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M22.46 6c-.77.35-1.6.58-2.46.69c.88-.53 1.56-1.37 1.88-2.38c-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29c0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15c0 1.49.75 2.81 1.91 3.56c-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07a4.28 4.28 0 0 0 4 2.98a8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21C16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56c.84-.6 1.56-1.36 2.14-2.23Z"/>
                    </svg>
                    <!-- facebook svg -->
                    <svg class="text-white hover:text-button-primary cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M20 12.05a8 8 0 1 0-9.25 8v-5.67h-2v-2.33h2v-1.77a2.83 2.83 0 0 1 3-3.14a11.92 11.92 0 0 1 1.79.16v2h-1a1.16 1.16 0 0 0-1.3 1.26v1.51h2.22l-.36 2.33h-1.85V20A8 8 0 0 0 20 12.05Z"/>
                    </svg>
                </span>
            </div>
            <div class="w-full flex flex-col space-y-3 text-white">
                <p class="md:text-xl text-sm font-medium">Discover Talent</p>
                <hr class="w-full text-white"/>
                <span class="flex flex-col space-y-1 md:text-lg text-xs">
                    <p @click="openTalents(1)" class="hover:text-button-primary hover:underline cursor-pointer">Find talents</p>
                    <p @click="$router.push('/about')" class="hover:text-button-primary hover:underline cursor-pointer">How it works</p>
                    <p class="hover:text-button-primary hover:underline cursor-pointer">Terms of Service</p>
                    <p @click="$router.push('/privacypolicy')" class="hover:text-button-primary hover:underline cursor-pointer">Privacy Policy</p>
                    <p v-if="!userPresent" @click="openTalents(2)" class="hover:text-button-primary hover:underline  cursor-pointer">Sign up</p>
                </span>
            </div>
            <div class="w-full flex flex-col space-y-3 text-white">
                <p class="md:text-xl text-sm font-medium">ROBiT</p>
                <hr class="w-full text-white"/>
                <span class="flex flex-col space-y-1 md:text-lg text-xs">
                    <p @click="$router.push('/about')" class="hover:text-button-primary hover:underline cursor-pointer">About Us</p>
                    <p @click="$router.push('/resources')" class="hover:text-button-primary hover:underline cursor-pointer">Resources</p>
                    <p @click="$router.push('/resources')" class="hover:text-button-primary hover:underline cursor-pointer">FAQ</p>
                </span>
            </div>
            <div class="w-full flex flex-col md:col-span-1 col-span-2 space-y-3 text-white">
                <p class="md:text-xl text-sm font-medium">Contact Us</p>
                <hr class="w-full text-white"/>
                <span class="flex flex-col space-y-1 md:text-lg text-xs">
                    <span class="flex">
                        <svg class="inline-block mr-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 13.5C11.2583 13.5 10.5333 13.2801 9.91661 12.868C9.29993 12.456 8.81928 11.8703 8.53545 11.1851C8.25162 10.4998 8.17736 9.74584 8.32206 9.01841C8.46675 8.29098 8.8239 7.6228 9.34835 7.09835C9.8728 6.5739 10.541 6.21675 11.2684 6.07206C11.9958 5.92736 12.7498 6.00162 13.4351 6.28545C14.1203 6.56928 14.706 7.04993 15.118 7.66661C15.5301 8.2833 15.75 9.00832 15.75 9.75C15.7488 10.7442 15.3533 11.6973 14.6503 12.4003C13.9473 13.1033 12.9942 13.4988 12 13.5ZM12 7.5C11.555 7.5 11.12 7.63196 10.75 7.8792C10.38 8.12643 10.0916 8.47783 9.92127 8.88896C9.75098 9.3001 9.70642 9.7525 9.79323 10.189C9.88005 10.6254 10.0943 11.0263 10.409 11.341C10.7237 11.6557 11.1246 11.87 11.561 11.9568C11.9975 12.0436 12.4499 11.999 12.861 11.8287C13.2722 11.6584 13.6236 11.37 13.8708 11C14.118 10.63 14.25 10.195 14.25 9.75C14.2494 9.15345 14.0122 8.5815 13.5903 8.15967C13.1685 7.73784 12.5966 7.5006 12 7.5Z" fill="white"/>
                            <path d="M12 22.5L5.67301 15.0382C5.58509 14.9262 5.49809 14.8135 5.41201 14.7C4.33124 13.2763 3.74739 11.5374 3.75001 9.75C3.75001 7.56196 4.6192 5.46354 6.16638 3.91637C7.71355 2.36919 9.81197 1.5 12 1.5C14.188 1.5 16.2865 2.36919 17.8336 3.91637C19.3808 5.46354 20.25 7.56196 20.25 9.75C20.2526 11.5366 19.669 13.2747 18.5888 14.6978L18.588 14.7C18.588 14.7 18.363 14.9955 18.3293 15.0353L12 22.5ZM6.60901 13.7963C6.61051 13.7963 6.78451 14.0272 6.82426 14.0767L12 20.181L17.1825 14.0685C17.2155 14.0272 17.391 13.7948 17.3918 13.794C18.2746 12.6308 18.7517 11.2103 18.75 9.75C18.75 7.95979 18.0388 6.2429 16.773 4.97703C15.5071 3.71116 13.7902 3 12 3C10.2098 3 8.49291 3.71116 7.22704 4.97703C5.96117 6.2429 5.25001 7.95979 5.25001 9.75C5.24844 11.2112 5.72609 12.6326 6.60976 13.7963H6.60901Z" fill="white"/>
                        </svg>
                        <p class="ml-1">Sabit Building, Airport Road, Addis Ababa, Ethiopia</p>
                    </span>
                    <span class="">
                        <svg class="inline-block mr-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 9.75H16.5C16.4994 9.15345 16.2622 8.5815 15.8403 8.15967C15.4185 7.73784 14.8466 7.5006 14.25 7.5V6C15.2442 6.00119 16.1973 6.39666 16.9003 7.09966C17.6033 7.80267 17.9988 8.7558 18 9.75Z" fill="white"/>
                            <path d="M20.9998 9.75H19.4998C19.4982 8.3581 18.9446 7.02367 17.9603 6.03944C16.9761 5.05522 15.6417 4.50159 14.2498 4.5V3C16.0394 3.00199 17.7551 3.71378 19.0206 4.97922C20.286 6.24466 20.9978 7.9604 20.9998 9.75ZM19.4998 21.75H19.3723C4.63478 20.9025 2.54228 8.4675 2.24978 4.6725C2.20407 4.07756 2.39654 3.48883 2.78488 3.03579C3.17321 2.58276 3.7256 2.30253 4.32053 2.25675C4.38003 2.25225 4.43978 2.25 4.49978 2.25H8.45228C8.7527 2.24971 9.04628 2.33963 9.29501 2.50812C9.54373 2.67661 9.73613 2.9159 9.84728 3.195L10.9873 6C11.097 6.27266 11.1243 6.57156 11.0656 6.85957C11.0069 7.14758 10.865 7.412 10.6573 7.62L9.05978 9.2325C9.3083 10.651 9.98707 11.9587 11.004 12.9784C12.021 13.998 13.3269 14.6802 14.7448 14.9325L16.3723 13.32C16.5834 13.1146 16.8503 12.9759 17.1397 12.9213C17.4292 12.8666 17.7283 12.8983 17.9998 13.0125L20.8273 14.145C21.1022 14.2597 21.3367 14.4536 21.501 14.702C21.6653 14.9504 21.7519 15.2422 21.7498 15.54V19.5C21.7498 20.0967 21.5127 20.669 21.0908 21.091C20.6688 21.5129 20.0965 21.75 19.4998 21.75ZM4.49978 3.75C4.40129 3.7497 4.30371 3.76881 4.2126 3.80623C4.12149 3.84365 4.03865 3.89864 3.96879 3.96808C3.89894 4.03751 3.84345 4.12003 3.80548 4.21091C3.76752 4.30179 3.74783 4.39926 3.74753 4.49775C3.74753 4.51875 3.74828 4.5395 3.74978 4.56C4.09478 9 6.30728 19.5 19.4548 20.25C19.6533 20.2619 19.8484 20.1945 19.9972 20.0625C20.146 19.9306 20.2363 19.745 20.2483 19.5465L20.2498 19.5V15.54L17.4223 14.4075L15.2698 16.545L14.9098 16.5C8.38478 15.6825 7.49978 9.1575 7.49978 9.09L7.45478 8.73L9.58478 6.5775L8.45978 3.75H4.49978Z" fill="white"/>
                        </svg>
                        +251-911084977</span>
                    <span class="">
                        <svg class="inline-block mr-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_394_386)">
                            <path d="M21.3335 4H2.66683C2.31321 4 1.97407 4.14048 1.72402 4.39052C1.47397 4.64057 1.3335 4.97971 1.3335 5.33333V18.6667C1.3335 19.0203 1.47397 19.3594 1.72402 19.6095C1.97407 19.8595 2.31321 20 2.66683 20H21.3335C21.6871 20 22.0263 19.8595 22.2763 19.6095C22.5264 19.3594 22.6668 19.0203 22.6668 18.6667V5.33333C22.6668 4.97971 22.5264 4.64057 22.2763 4.39052C22.0263 4.14048 21.6871 4 21.3335 4ZM20.3068 18.6667H3.7735L8.44016 13.84L7.48016 12.9133L2.66683 17.8933V6.34667L10.9535 14.5933C11.2033 14.8417 11.5412 14.9811 11.8935 14.9811C12.2457 14.9811 12.5837 14.8417 12.8335 14.5933L21.3335 6.14V17.8067L16.4268 12.9L15.4868 13.84L20.3068 18.6667ZM3.54016 5.33333H20.2535L11.8935 13.6467L3.54016 5.33333Z" fill="white"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_394_386">
                            <rect width="24" height="24" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                        Info@ROBiT.com</span>
                </span>
            </div>
        </div>
        <p class="pb-8 md:text-base text-sm text-center text-white">
            <svg class="inline mr-1 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zm0 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208s-93.3 208-208 208zm-.9-288c21.06 0 40.92 8.312 55.83 23.38c9.375 9.344 24.53 9.5 33.97.156c9.406-9.344 9.469-24.53.156-33.97c-24-24.22-55.95-37.56-89.95-37.56c0 0 .032 0 0 0c-33.97 0-65.95 13.34-89.95 37.56c-49.44 49.88-49.44 131 0 180.9c24 24.22 55.98 37.56 89.95 37.56c.032 0 0 0 0 0c34 0 65.95-13.34 89.95-37.56c9.312-9.438 9.25-24.62-.156-33.97c-9.438-9.312-24.59-9.219-33.97.156c-14.91 15.06-34.77 23.38-55.83 23.38c0 0 .031 0 0 0c-21.09 0-40.95-8.312-55.89-23.38c-30.94-31.22-30.94-82.03 0-113.3C214.2 184.3 234 176 255.1 176z"/></svg>
            2023 ROBiT. All right reserved.
        </p>
    </div>
</template>
<script>
export default {
    data () {
        return {
            screenWidth: window.screen.availHeight,
            screenHeight: window.screen.availWidth
        }
    },
    computed:{
        userPresent(){
            return this.$store.getters['auth/getUser']
        }
    },
    methods:{
        openTalents(value){
            if(value== 1){
                 if(this.userPresent){
                this.$router.push('/explore')
                }else{
                    this.$emit('openModal',1)
                }
            }else{
                if(this.userPresent){
                    // do nothing
                }else{
                    this.$emit('openModal', 2)
                }
            }
        }
       
    }
}
</script>
<style>
.footer-background{
    background-color: #171B1D;
}
</style>