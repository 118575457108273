<template>
    <div class="absolute top-0 left-0 bg-transparent w-full 2xl:pt-12 md:pt-6 pt-2 xl:px-24 lg:px-20 md:px-16 px-2  z-10">
        <div 
        :class="[whiteBg ? 'bg-white': 'bg-transparent']" 
        class="w-full xl:pl-[100px] md:pl-10 pl-0 xl:pr-8 md:pr-5 md:py-4 py-2 rounded-[50px] flex justify-between items-center font-Poppins">
            <img @click="$router.push('/')" class="md:order-first order-2 xl:h-14 h-7 cursor-pointer" src="@/assets/logo_black.png" />
            <span 
            :class="[whiteBg ? 'text-black': 'text-white']"
            class="md:order-auto order-1 text-3xl cursor-pointer mx-2 md:hidden static z-10" 
            v-on:click="menuOpen = !menuOpen">
                <svg name="menuIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" 
                class="inline w-8 h-8"
                v-if="!menuOpen">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
            </span> 
            <nav 
            :class="[menuOpen ? 'left-0' : 'opacity-0 left-[-100%]']"
            class="md:opacity-100 md:static absolute md:bg-transparent bg-black top-0  md:h-auto h-screen md:w-auto w-full md:px-0 px-6 md:py-0 py-6 transition ease-in-out duration-500">
                <span class="md:hidden mb-10 w-1/2 flex justify-between items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" 
                    @click="(menuOpen = !menuOpen)"
                    class="inline w-7 h-7 cursor-pointer text-white" >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                     <img class="h-7" src="@/assets/logo_black.png" />
                </span>
                <ul 
                class="md:text-xl text-sm flex md:flex-row flex-col md:justify-start md:pl-0 pl-12 md:space-y-0 space-y-4 md:h-auto h-screen"
                :class="[whiteBg ? 'md:text-black/60 text-white': 'text-white']"
                >
                    <li @click="newPage(1)"  :class="[menuClicked == 1 ? 'text-button-primary': 'text-current']" class="cursor-pointer">Discover Talents</li>
                    <li @click="newPage(2)" :class="[menuClicked == 2 ? 'text-button-primary': 'text-current']" class="md:ml-9 ml-0 cursor-pointer">About us</li>
                    <li @click="newPage(3)" :class="[menuClicked == 3 ? 'text-button-primary': 'text-current']" class="md:ml-9 ml-0 cursor-pointer">Resources</li>
                    <li v-if="!userPresent" @click="$emit('openModal',1)" class="cursor-pointer md:hidden block">Login</li>
                    <li v-if="!userPresent" class="md:hidden block justify-end"><button @click="$emit('openModal',2)" class="bg-button-primary text-white text-base py-2 px-4">Sign up</button></li>
                </ul>
            </nav>
            <button 
            v-if="!userPresent" 
            @click="$emit('openModal',2)" 
            :class="[whiteBg ? 'text-black':'text-white']"
            class="md:hidden block mr-2 order-last border-none text-sm font-semibold">
                Sign Up
            </button>
            <span v-if="!userPresent" class="md:order-last order-3  md:flex hidden">
                <button
                @click="openModal(1)"
                :class="[whiteBg ? 'px-0 text-black/60' : 'xl:px-11 px-7 text-black']"
                class="border-transparent bg-white  py-2 mr-[52px] rounded-lg text-xl">
                    Login
                </button>
                <button
                @click="openModal(2)"
                :class="[whiteBg ? 'rounded-[50px]' : 'rounded']"
                class="bg-button-primary xl:px-11 px-7 py-3  text-xl text-white font-semibold">
                    Sign Up
                </button>
                
            </span>
            <span class="order-last" v-else>
                <p :class="[whiteBg ? 'text-robit-black': 'text-white']" class="relative text-center flex items-center">
                    <span class="font-medium text-lg md:block hidden">{{ userPresent.first_name }} {{ userPresent.last_name}}</span>
                    <svg @click="accountDetails = !accountDetails" class="ml-2 w-7 h-7 cursor-pointer" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 256 256"><path fill="currentColor" d="M172 120a44 44 0 1 1-44-44a44 44 0 0 1 44 44Zm60 8A104 104 0 1 1 128 24a104.2 104.2 0 0 1 104 104Zm-16 0a88 88 0 1 0-153.8 58.4a81.3 81.3 0 0 1 24.5-23a59.7 59.7 0 0 0 82.6 0a81.3 81.3 0 0 1 24.5 23A87.6 87.6 0 0 0 216 128Z"/></svg>
                    <div v-if="accountDetails" class="absolute top-6 right-0 py-2 px-3 flex items-center bg-white shadow-md">
                        <svg class="mr-2 text-button-primary" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12h-9.5m7.5 3l3-3l-3-3m-5-2V6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h5a2 2 0 0 0 2-2v-1"/></svg>
                        <button @click="logout" class="text-sm text-black/70 hover:text-button-primary hover:underline cursor-pointer">Log out</button>
                    </div>
                </p>                
            </span>
            
        </div>
    </div>
</template>
<script>
export default {
    props:["whiteBg","menuClicked"],
    data () {
        return {
            menuOpen: false,
            accountDetails: false
        }
    },
    computed:{
        userPresent () {
            return this.$store.getters['auth/getUser']
        }
    },
    methods: {
        newPage(value){
            this.menuOpen = !this.menuOpen
            if(value === 1){
                if(this.userPresent){
                    this.$router.push('/explore')
                }else{
                    this.$emit('openModal',1)
                }
                
            }else if(value === 2){
                this.$router.push('/about')
            }else if(value === 3){
                this.$router.push('/resources')
            }else if(value === 4){
                this.$emit('openModal',2)
            }
            else {
                console.log('value is not clicking bro ')
            }            
        },
        logout(){
            this.$store.commit('auth/LOGOUT')
            window.location.reload()
        },
        openModal(value){
            if(value == 1){
                this.$emit('openModal',1)
            }
            if(value == 2){
                this.$emit('openModal',2)
            }
            this.menuOpen = !this.menuOpen
        }
    },
    mounted () {
       
    }
}
</script>