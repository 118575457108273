<template>
    <div class="relative w-full md:px-0 px-5">
        <div class="md:-ml-16 -ml-4 md:h-[500px] h-auto flex  overflow-hidden">
            <carousel-item 
            class="md:ml-16 ml-4 " 
            v-for="(talent, index) in talents" 
            :key="`talent-${index}`" 
            :talent="talent" 
            :current-slide="currentSlide" 
            :index="index"
            @talentClicked="talentClick" />
            <CarouselControls @prev="prev" @next="next" />
        </div>
    </div>
</template>
<script>
// component
import CarouselItem from './CarouselItem.vue' 
import CarouselControls from './CarouselControls.vue'
export default {
    props: ["talents"],
    components: {
        CarouselItem,
        CarouselControls
    },
    data () {
        return {
            width: screen.width,
            currentSlide: [0,1,2],
            slideInterval: null
        }
    },
  computed:{
    userPresent(){
      return this.$store.getters['auth/getUser']
    }
  },
    methods: {
        setCurrentSlide (index) {
            this.currentSlide = index
        },
        next () {
            if(this.currentSlide[this.currentSlide.length - 1] < this.talents.length -1){
                for(var key in this.currentSlide){
                    this.currentSlide[key] += 1
                }
            }else{
                if(this.width < 768){
                    this.currentSlide = []
                }else{
                    this.currentSlide = [0,1,2]
                }                
            }
        },
        prev () {            
            if(this.currentSlide[0] > 0){
                for(var key in this.currentSlide){
                    this.currentSlide[key] -= 1
                }
            }else{
                if(this.width < 768){
                    this.currentSlide = []
                }else{
                    this.currentSlide = [0,1,2]
                }                
            }
        },
        talentClick(value){
            if(this.userPresent){
                 localStorage.setItem('talentID',value.id)
                this.$router.push({name: 'Profile', params:{name: value.name}})
            }else{
                this.$emit('openModal',1)
            }
           
        }
    },
    mounted(){
        var width = screen.width
        console.log(width)
        if(width < 768){
            this.currentSlide = [0,1]
        }
        // this.slideInterval = setInterval(()=>{
        //     if(this.currentSlide[2] <  this.talents.length - 1 ){
        //         for(var key in this.currentSlide){
        //             this.currentSlide[key] += 1
        //         }
        //     }else{
            // if(this.width < 768){
            //         this.currentSlide = []
            //     }else{
            //         this.currentSlide = [0,1,2]
            //     }                
            // }
        // }, 3000)
    },
    beforeUnmount () {
        clearInterval(this.slideInterval)
    }
}
</script>
<style>
.carousel {
    display: flex;
    justify-content: center;
}

.carousel-inner {
    position: relative;
    width: 900px;
    overflow: hidden;

}
</style>